import {App} from './App';
import {APIHandler} from './api/APIHandler';
// import { RenderSwiperSteps } from './handlers/RenderSwiperSteps'
import {Office} from "./api/Office";
import {getTranslation} from "./translations";

const apiHandlerInstance = new APIHandler(App.apiBaseUrl, App.nonce);

export class Step4DriverDataForm {

    private office: Office;
    private payLink: string;
    private sessionID: string;

    constructor() {
        this.payLink = '';
        this.sessionID = '';
        this.office = new Office();
        App.bookingForm.addEventListener('submit', (event) => this.handleBookingFormSubmit(event));
        App.bookingForm.classList.remove('hidden');
        // App.changeButtonState(App.bookingSubmitButton, 'enabled', getTranslation((window as any).langValue, 'bookNow'));
        // @ts-ignore
        App.topScroll.scrollIntoView({behavior: 'smooth'});
        this.setFormData().then(r => console.log(''));
    }

    public async handleBookingFormSubmit(event: Event): Promise<void> {
        event.preventDefault();

        const form = document.getElementById('bookingFormId') as HTMLFormElement;
        const submitButton = document.getElementById('bookingFinalFormButtonId') as HTMLButtonElement;

        if (!form.checkValidity()) {
            form.reportValidity();
            return;
        }

        // Deshabilitar el botón y cambiar el texto
        submitButton.disabled = true;
        submitButton.textContent =  getTranslation((window as any).langValue, 'sendingToStripe');

        const formData = new FormData(form);
        const customerEmail = formData.get('email') as string;
        const customerName = formData.get('name') as string;

        try {
            // Guardar los datos en el CPT de reservas en WordPress
            const response = await App.APIHandler.postData({
                url: App.saveToWordPressEndpoint,
                data: formData
            });

            const responseJSON = JSON.parse(response);
            const bookingPostId = responseJSON.data['bookingPostId'];

            if (!responseJSON.success || !bookingPostId) {
                // // console.error('Failed to save booking in WordPress');
                App.showError('Failed to save booking in WordPress');
                return;
            }

            // // console.log('Booking saved in WordPress with ID: ' + bookingPostId);

            // Obtener el enlace de pago y el ID de la sesión de Stripe
            this.payLink = await this.getPaidLink(customerEmail, customerName);

            if (!this.payLink) {
                // // console.error('Failed to create payment link');
                App.showError('Failed to create payment link');
                return;
            }

            // // console.log('Payment link created: ' + this.payLink);
            // // console.log('Stripe session ID: ' + this.sessionID);

            // Actualizar el post de reserva con el ID de sesión de Stripe
            const updateResponse = await this.updateBookingStripeSessionIdInWordPress(this.sessionID, bookingPostId);

            if (!updateResponse.success) {
                // // console.error('Failed to update booking with Stripe session ID');
                App.showError('Failed to update booking with Stripe session ID');
                return;
            }

            // // console.log('Booking CPT updated with Stripe session ID');

            // Redirigir a la pasarela de pagos de Stripe solo si todo ha sido exitoso
            // // console.log('Redirecting to payment link: ' + this.payLink);
            window.location.href = this.payLink;

        } catch (error) {
            // // console.error('Error in handleBookingFormSubmit: ' + error);
            App.showError('Error in handleBookingFormSubmit');
        }
    }

    public async updateBookingStripeSessionIdInWordPress(sessionId: string, postID: string): Promise<any> {
        const formData = new FormData();
        formData.append('action', 'update_booking_with_stripe_session_id');
        formData.append('session_id', sessionId);
        formData.append('post_id', postID);

        // // console.log('Saving session ID to WordPress: ' + sessionId);
        // // console.log('Post ID: ' + postID);

        try {
            const response = await apiHandlerInstance.postData({
                url: '/wp-admin/admin-ajax.php',
                data: formData
            });

            const result = JSON.parse(response);

            if (result.success) {
                // // console.log('Session ID updated in WordPress');
                return { success: true };
            } else {
                // // console.error('Error updating session ID in WordPress');
                return { success: false, error: 'Error updating session ID' };
            }
        } catch (error) {
            // // console.error('Error in updateBookingStripeSessionIdInWordPress: ' + error);
            return { success: false, error: error };
        }
    }

    private async getPaidLink( customerEmail: string, customerName: string ): Promise<string> {
        const formData = new FormData();

        try {
            formData.append('action', 'create_stripe_payment_link');
            formData.append('amount', App.summaryData.price.toString());
            formData.append('customer_email', customerEmail);
            formData.append('customer_name', customerName);

            const response = await apiHandlerInstance.postData({
                url: '/wp-admin/admin-ajax.php',
                data: formData,
            });

            const result = JSON.parse(response);

            if (result.success) {
                // // console.log(result.data);
                // // console.log('Session ID: ' + result.data.id);
                this.sessionID = result.data.id;
                const finalBookingSessionId = document.getElementById('finalBookingSessionId') as HTMLInputElement;
                finalBookingSessionId.value = this.sessionID;
                // // // console.log('added session id to form: ' + this.sessionID);
                return result.data.url;
            } else {
                // // // console.log('Error creating payment link: ' + result.data);
                return '';
            }

        } catch (error) {
            // // // console.log('Error creating payment link');
            App.showError('Error fetching payment link');
        }

        return '';

    }

    private async setFormData(): Promise<void> {
        if (!App.summaryData) {
            return;
        }

        // @ts-ignore
        document.getElementById('finalBookingCarImage').src = App.summaryData.vehicleImage;

        const finalBookingFromDate = document.getElementById('finalBookingFromDate') as HTMLInputElement;
        const finalBookingFromDateInput = document.getElementById('finalBookingFromDateInput') as HTMLInputElement;
        const finalBookingFromTime = document.getElementById('finalBookingFromTime') as HTMLInputElement;
        const finalBookingFromTimeInput = document.getElementById('finalBookingFromTimeInput') as HTMLInputElement;
        const finalBookingFromLocation = document.getElementById('finalBookingFromLocation') as HTMLInputElement;
        const finalBookingFromLocationInput = document.getElementById('finalBookingFromLocationInput') as HTMLInputElement;
        const finalBookingToDate = document.getElementById('finalBookingToDate') as HTMLSpanElement;
        const finalBookingToDateInput = document.getElementById('finalBookingToDateInput') as HTMLInputElement;
        const finalBookingToTime = document.getElementById('finalBookingToTime') as HTMLSpanElement;
        const finalBookingToTimeInput = document.getElementById('finalBookingToTimeInput') as HTMLInputElement;
        const finalBookingToLocation = document.getElementById('finalBookingToLocation') as HTMLSpanElement;
        const finalBookingToLocationInput = document.getElementById('finalBookingToLocationInput') as HTMLInputElement;

        const finalBookingExtrasContainer = document.getElementById('finalBookingExtrasContainer') as HTMLDivElement;

        const finalBookingCar = document.getElementById('finalBookingCar') as HTMLSpanElement;
        const finalBookingCarCategoryId = document.getElementById('finalBookingCarCategoryId') as HTMLInputElement;
        const finalBookingExtras = document.getElementById('finalBookingExtras') as HTMLSpanElement;
        const finalBookingExtrasInput = document.getElementById('finalBookingExtrasInput') as HTMLInputElement;
        const finalBookingCarNameInput = document.getElementById('finalBookingCarNameInput') as HTMLInputElement;

        const finalBookingTotalPrice = document.getElementById('finalBookingTotalPrice') as HTMLSpanElement;
        const finalBookingTotalPriceInput = document.getElementById('finalBookingTotalPriceInput') as HTMLInputElement;

        finalBookingFromDate.textContent = App.summaryData.pickupDate;
        finalBookingFromDateInput.value = App.summaryData.pickupDate;
        finalBookingFromTime.textContent = App.summaryData.pickupTime;
        finalBookingFromTimeInput.value = App.summaryData.pickupTime;

        finalBookingFromLocation.textContent = <string>await this.office.getOfficeDetails(App.summaryData.pickupLocation);
        finalBookingFromLocationInput.value = App.summaryData.pickupLocation;
        finalBookingToLocation.textContent = <string>await this.office.getOfficeDetails(App.summaryData.returnLocation);
        finalBookingToLocationInput.value = App.summaryData.returnLocation

        finalBookingToDate.textContent = App.summaryData.returnDate;
        finalBookingToDateInput.value = App.summaryData.returnDate;
        finalBookingToTime.textContent = App.summaryData.returnTime;
        finalBookingToTimeInput.value = App.summaryData.returnTime;

        finalBookingCar.textContent = App.summaryData.vehicleName;
        finalBookingCarCategoryId.value = App.summaryData.vehicleCategory;
        finalBookingCarNameInput.value = App.summaryData.vehicleName;

        if (App.summaryData.extras.length > 0) {
            finalBookingExtrasContainer.classList.remove('hidden');
        }
        finalBookingExtras.textContent = App.summaryData.extras.map(extra => extra.name).join(', ');
        finalBookingExtrasInput.value = App.summaryData.extras.map(extra => extra.id).join('-|-');

        finalBookingTotalPrice.textContent = App.summaryData.price.toString() + '€';
        finalBookingTotalPriceInput.value = App.summaryData.price.toString();

        const bookingFinalFormButton = document.getElementById('bookingFinalFormButtonId') as HTMLAnchorElement;


        bookingFinalFormButton.addEventListener(
            'click',
            (event) => {
                event.preventDefault();
                this.handleBookingFormSubmit( event );
            }
        )
    }
}
